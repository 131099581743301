img,
picture {
  max-width: 100%;
  height: auto;
}

.text-columns-2 {
  columns: 2;
}

.text-columns-3 {
  columns: 3;
}

figure{
  display: table;
}
figcaption{
  display: table-caption;
  caption-side: bottom;
}

.image-right {
  @extend .mx-auto;
  @extend .my-2;

  &.picture-variant-large {
    @extend .float-xxl-end;
    @extend .ms-xxl-2;
  }

  &.picture-variant-medium {
    @extend .float-xl-end;
    @extend .ms-xl-2;
  }

  &.picture-variant-small {
    @extend .float-md-end;
    @extend .ms-md-2;
  }
}

.image-left {
  @extend .mx-auto;
  @extend .my-2;

  &.picture-variant-large {
    @extend .float-xxl-start;
    @extend .me-xxl-2;
  }

  &.picture-variant-medium {
    @extend .float-xl-start;
    @extend .me-xl-2;
  }

  &.picture-variant-small {
    @extend .float-md-start;
    @extend .me-md-2;
  }
}