:root {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Colors
  //
  // Generate palettes for full colors, grays, and theme colors.

  @each $color,
  $value in $plone-colors {
    --plone-#{$color}: #{$value};
  }

  @each $color,
  $value in $state-colors {
    --plone-state-#{$color}: #{$value};
  }
}